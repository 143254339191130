import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '@cca/ui';
import {
  AuthenticationFacade,
  PermissionService,
} from '@cca-common/authentication';
import { TranslocoModule } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cca-header-menu',
  standalone: true,
  imports: [MatMenuModule, IconComponent, TranslocoModule, RouterLink],
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent {
  permissions = inject(PermissionService);
  public authentication = inject(AuthenticationFacade, {
    optional: true,
  });

  avatarURL = computed(() => {
    const user = this.authentication?.user();
    return user?.userDetail?.avatarURL ?? null;
  });
}
