import { computed, inject, Injectable } from '@angular/core';
import { AuthenticationFacade } from '@cca-common/authentication';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';

export type Flavor = 'primary' | 'warning' | 'highlight' | 'danger';

export interface MessageBannerContent {
  flavor: Flavor;
  description: string;
  linkText: string;
  link: string;
}

@Injectable({
  providedIn: 'root',
})
// could be refactored to allow adding messages if there is a requirement/need for such functionality, else leave as is.
export class MessageBannerService {
  private authentication = inject(AuthenticationFacade, {
    optional: true,
  });

  public messages = computed<MessageBannerContent[]>(() => {
    const email = this.authentication?.user()?.email;
    if (this.authentication && !email) {
      return [
        {
          flavor: 'warning',
          description: t('web.userMissingEmail'),
          linkText: t('web.addUserEmail'),
          link: '/user-profile/details',
        },
      ];
    }
    return [];
  });
}
