<ng-container *transloco="let t; read: 'web'">
  <div class="flex items-center">
    <a
      [matMenuTriggerFor]="menu"
      id="userMenu"
      class="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full info-surface-light info-text-dark"
    >
      @if (avatarURL()) {
        <div>
          <img width="40" height="40" [src]="avatarURL()" />
        </div>
      } @else {
        <cca-icon class="text-base" icon="user"></cca-icon>
      }
    </a>
  </div>
  <mat-menu #menu="matMenu">
    @if (permissions.UserProfile()) {
      <a
        class="text-neutral-caption"
        mat-menu-item
        routerLink="/user-profile"
        href="/user-profile"
      >
        {{ t('profile') }}
      </a>
    }

    @if (authentication) {
      <button
        mat-menu-item
        (click)="authentication.logout()"
        class="text-neutral-caption"
      >
        {{ t('logout') }}
      </button>
    }

    <a
      mat-menu-item
      href="https://www.ctrlchain.com/en/privacy-policy"
      class="text-neutral-caption"
      target="_blank"
    >
      {{ t('privacyPolicy') }}
    </a>
  </mat-menu>
</ng-container>
