import { ComponentRef, Injectable, NgZone, inject } from '@angular/core';
import { exhaustMap, filter, finalize, takeUntil } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationOverlayComponent } from './notification-overlay';
import { AuthenticationFacade } from '@cca-common/authentication';
import { NotificationViewModel } from '@cca-infra/notification-service/v1';
import { SignalrService } from '@cca-infra/core';
import { filterNull } from '@cca-common/cdk';

@Injectable({
  providedIn: 'root',
})
export class CommonNotificationService {
  private overlay = inject(Overlay);
  private ngZone = inject(NgZone);

  /**SIGNAL R */
  private signalR = inject(SignalrService);
  private authentication = inject(AuthenticationFacade);

  componentRef: ComponentRef<NotificationOverlayComponent>;

  constructor() {
    const positionStrategy = this.overlay.position().global().right('0');

    const overlayRef = this.overlay.create({
      disposeOnNavigation: false,
      positionStrategy: positionStrategy,
      direction: 'ltr',
      panelClass: 'pointer-events-none',
    });
    const userProfilePortal = new ComponentPortal(NotificationOverlayComponent);
    this.componentRef = overlayRef.attach(userProfilePortal);
  }

  startListening() {
    this.authentication.user$
      .pipe(
        filter(filterNull),
        exhaustMap(() => {
          // initiate connection
          const connection = this.signalR.startConnection(
            'notification',
            'notificationservicehub',
          );

          //subscribe to messages BE -> FE
          return connection.events$.pipe(
            takeUntil(this.authentication.user$.pipe(filter((user) => !user))),
            finalize(() => {
              connection.close();
            }),
          );
        }),
      )
      .subscribe({
        next: (message) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let parsed: any;
          try {
            parsed = JSON.parse(message);
          } catch {
            return;
          }

          //placeholder
          this.ngZone.run(() => {
            this.componentRef.instance.addNotification(
              parsed as NotificationViewModel,
            );
          });
        },
        complete: () => {
          //placeholder
        },
        error: () => {
          //placeholder
        },
      });
  }
}
